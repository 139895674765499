import React, { Component } from 'react';

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import history from 'history';




import Proyectos from './components/proyecto'
import ProyectosAdd from './components/proyecto/add'
import Disciplina from './components/disciplina'
import DisciplinaAdd  from './components/disciplina/add'
import TipoInspeccion from './components/tipoInspeccion'
import TipoInspeccionAdd from './components/tipoInspeccion/add'
import AppBar from './components/appBar';
import LoadPage from './components/loadPage'
import LogEngie from './components/logEngie'
import Dashboard from './components/dashboard';



import { auth } from './services/firebase';
import { cerrarSesion } from './services/auth.js';

import upDateUserAuth from './redux/actions/actionbar/upDateUserAuth'



import { connect } from 'react-redux';



import DrawerMain from './components/draweMain'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
//const history = createHistory()


class App extends Component {

    constructor(props) {
        super(props);


        //this.hideModalContrato = this.hideModalContrato.bind(this);
    }
    state = {
        form: {
            email: '',
            password: '',
        },
        confirmar: false,
        mensaje: "",
        error: "",
        mostrarMensaje: false,
        loading: true,
        usuario: ""

    }


    handleLogout = async () => {
        try {
            await cerrarSesion();
            this.setState({
                ...this.state, confirmar: false,
                usuario: false
            })
        } catch (error) {
            console.log(error)
        }
    }

    login = () => {
        var csrfToken = "";
        if (this.getQueryVariable("csrfToken")) {
            csrfToken = this.getQueryVariable("csrfToken");
        } else if (localStorage.getItem("csrfToken")) {
            csrfToken = localStorage.getItem("csrfToken");
        }

        localStorage.setItem("csrfToken", csrfToken);
        setTimeout(() => {
            fetch(`https://api-usuarios.cydocs.cl/login/verifySessionCookie?_csrf=${csrfToken}`,
                {
                    method: 'post',
                    credentials: 'include',
                })
                .then(response => response.json())
                .then(jsonResponse => { auth.signInWithCustomToken(jsonResponse.token) })
                .catch(error => {
                    console.log(error);
                    window.location.href = `https://account.cydocs.cl/?origin=${window.location.href.split("?")[0]}`;
                })
        }, 1000);

    }
    getQueryVariable(variable) {
        const query = window.location.search.substring(1);
        const vars = query.split("&");
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split("=");
            if (pair[0] === variable) { return pair[1]; }
        }
        return (false);
    }

    componentWillUnmount() {
        this.authFirebaseListener && this.authFirebaseListener() // Unlisten it by calling it as a function
        this._isMounted = false;
    }

  

    componentDidMount() {
        const {
            upDateUserAuth,
        } = this.props;
        this._isMounted = true;
        const usuario = async () => {
            auth.onAuthStateChanged(async user => {
                try {
                    if (user) {
                        upDateUserAuth("UPDATE_AUTH_USER_ACTION_BAR", user.photoURL, user.displayName);
                        if (this._isMounted === true) {
                            this.setState({ loading: false })
                        }
                    } else {
                        this.login()
                    }
                } catch (error) {
                    console.log(error)
                }
            })
        }
        usuario();

    }
    render() {

        const { loading } = this.state
        const theme = createMuiTheme({
            typography:{fontFamily: ['Open Sans']},
            palette: {
                primary: {
                    light: '#a64c46',
                    main: '#731f1f',
                    dark: '#430000',
                    contrastText: '#fff',
                },
                secondary: {
                    light: '#4f5b62',
                    main: '#263238',
                    dark: '#000a12',
                    contrastText: '#fff',
                },
                blanco: {
                    light: '#fff',
                    main: '#fff',
                    dark: '#fff',
                    contrastText: '#fff',
                }
            },
        });

        const {
            openDialogContrato = false,
            textoModalContrato = "",
            codigoNuevoContrato = "",

        } = this.props

        const mainApp = (

            <MuiThemeProvider theme={theme}>
                <BrowserRouter>
                    <AppBar />
                    <DrawerMain />
                    <Switch>
                        <Route exact={true} path="/" component={LogEngie} />
                        <Route exact={true} path="/disciplina/add" component={DisciplinaAdd} />
                        <Route exact={true} path="/disciplina" component={Disciplina} />
                        <Route exact={true} path="/proyectos" component={Proyectos} />
                        
                        
                    </Switch>
                </BrowserRouter>
            </MuiThemeProvider>
        );
        const loadingScreen = (
            <MuiThemeProvider theme={theme}>
                <div><LoadPage /></div>
            </MuiThemeProvider>
        );

        return (<div>{loading ? loadingScreen : mainApp}</div>);
    }
}

function mapStateToProps(state) {
    
    return {

    }
}

const mapDispatchToProps = {
    upDateUserAuth,
};


// conectar un componente con redux 
export default connect(mapStateToProps, mapDispatchToProps)(App);



