import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Page from './page';
import { Formik } from "formik";
import * as Yup from "yup"
import { realTime } from '../../services/firebase'

import DialogBeforeAdd from '../modals/DialogGenerico'
import DialogLoad from '../modals/DialogLoading'


import showModalAction from '../../redux/actions/modal/showModalGenerico'
import showModalActionLoading from '../../redux/actions/modal/showModalLoading'

import hideModalAction from '../../redux/actions/modal/hideModalGenericoResetForm'
import hideModalActionLoading from '../../redux/actions/modal/hideModalLoading'

import TablaProyectos from './tabla/tabla_log'

class LogEngie extends Component {
    constructor(props) {

        super(props);

        // Definir en un state si se tiene que cargar 
        // informacion adicional al fomulario
        this.state = {
            loading: false,
            openDialog: false,
            openDialogLoading: false,
            gerentes: []
        };

        //
        // seccion de codigo encargado de bindear las functiones 
        // respectivas para el modal 
        this.hideModalAction = this.hideModalAction.bind(this);
        this.hideModalLoading = this.hideModalLoading.bind(this);

        this.closeModal = this.closeModal.bind(this);
        this.handleCloseReturnHome = this.handleCloseReturnHome.bind(this);

        this.deleteProyecto = this.deleteProyecto.bind(this);

        this.refreshData = this.refreshData.bind(this);
    }



    // submit function
    submitValues = async ({ nombre_proyecto, nombre_empresa, nombre_responsable, correo_responsable, correo_responsable_mandante }) => {
        console.log({
            nombre_proyecto,
            nombre_empresa,
            nombre_responsable,
            correo_responsable,
            correo_responsable_mandante,
        }
        );
        const {
            showModalAction,
            showModalActionLoading,
        } = this.props;

        showModalActionLoading("OPEN");
        setTimeout(() => {
            //TODO parametrizar url 
            var insert = realTime.ref('apps/App-16008/config/proyectos').push().set({
                nombre_proyecto: nombre_proyecto,
                contratista: [{
                    nombre_empresa: nombre_empresa,
                    nombre_responsable: nombre_responsable,
                    correo_responsable: correo_responsable
                }],
                correo_responsable_mandante: correo_responsable_mandante,
            }).then(async () => {
                this.hideModalLoading();
                showModalAction("openDialog", "titulo", "Se ha creado correctamente el proyecto");
            });
        }, 2000)


    };

    /**
     * @param resetForm 
     * 
     * Formulario encargado de realizar el reset del formulario
     * 
     */
    handleReset = (resetForm) => {
        resetForm();
    };

    /**
     * Funciron se encarga de cerrar el dialog
     */
    closeModal() {
        this.setState({
            openDialog: false,
        })
    }

    /**
     * funcion que se encarga de ocultar el modal 
     * y enviar funcion redux pra cerrar el modal 
     * con reset 
     * @todo refactorizar parametro
     * 
     */
    hideModalAction() {
        const {
            hideModalAction,
        } = this.props;
        hideModalAction("CLOSE_RESET_FORM")
        this.formik.resetForm();
    }
    /**
    * funcion que se encarga de ocultar el modal 
    * y enviar funcion redux pra cerrar el modal 
    * de loading
    * @todo refactorizar parametro
    * 
    */
    hideModalLoading() {
        const {
            hideModalActionLoading,
        } = this.props;

        hideModalActionLoading("CLOSE")
    }

    /**
     * funcion que se encarga de cerrar el modal 
     * setear el formulario y vueleve a la ruta indidcada
     * 
     */
    handleCloseReturnHome() {
        const {
            hideModalAction,
        } = this.props;
        //console.log(this.props)
        // //
        hideModalAction("CLOSE")
        this.formik.resetForm();
        this.props.history.push("/contrato")
    }

    /**
     *  
     * Se traer informacion que se desea pre cargar a la vista 
     *  
     * Sólo se ejecuta antes de que el componente sea montado
     * en el DOM. Si utilizas ecmascript 6, debes usar el método 
     * constructor pasándole las props como parámetro y usar el método 
     * super para activar correctamente el componente. 
     * 
     * asignacion de los estado cambiando el valor decalrado en el 
     * state.
     * @example
     * this.setState({
     *      gerentes: mandantesList,
     *      loading: false,
     * });
     * 
     * 
     * 
     */
    componentDidMount() {
        this.setState({ loading: false });
        realTime.ref("apps/App-16008/registros").orderByValue().once("value", async snapshot => {
            const proyectos = snapshot.val();
            console.log(proyectos);
            if (proyectos) {

                const proyectosList = Object.keys(proyectos).map((key, index) => ({
                    ...proyectos[key],
                    uid: key,
                    count: (index + 1)
                }))
                var newArray = [];
                var finalIsnpeccion = this.reverseArray(proyectosList);
                for (var i = finalIsnpeccion.length - 1; i >= 0; i--) {
                    console.log(finalIsnpeccion[i].estado === "aprobado")
                    console.log(finalIsnpeccion[i].estado);
                    if (proyectosList[i].estado === "aprobado") {
                        newArray.push(proyectosList[i]);
                    }

                }

                this.setState({
                    proyectos: newArray,
                    loading: false,
                });
                console.log("finish setstate", proyectosList);
            } else {
                this.setState({ gerentes: null, loading: false });
            }
        })


    }

    reverseArray(arr) {
        var newArray = [];
        for (var i = arr.length - 1; i >= 0; i--) {
            newArray.push(arr[i]);
        }
        return newArray;
    }


    deleteProyecto(data) {
        var path = '/contrato/update/' + data.uid;
        console.log(path);

    }

    /**
     * 
     *  1.- Asignar objeto con los valores a capturar
     *  2.- Use un validador de schema ej:
     *  const validationSchema = Yup.object(
     *       {
     *           nombre: Yup.string("Ingrese nombre gerencia").required("Nombre gerencia Requerido").strict(),
     *           codigo: Yup.string("Ingrese codigo").required("Codigo es requerida"),
     *           responsable: Yup.string("Ingrese codigo").required("Codigo es requerida"),
     *       });
     * 
     * @todo adjuntar titulo para mostrar en el modal, props entregados por redux
     */
    render() {

        const { proyectos } = this.state;

        return (
            <TablaProyectos
                data={proyectos}
                goTo={this.goTo}
                onClickEliminarProyecto={this.deleteProyecto}
                onViewerPDF={this.viewPDF}
                onViewerPDFCierre={this.viewPDFCierre}
                onRefreshData={this.refreshData}
            />
        );
    }

    viewPDF(data) {
        var url = "https://drive.google.com/file/d/" + data.id_pdf + "/view";
        window.open(url, "_blank") //to open new page
        console.log(data);
    }

    viewPDFCierre(data) {
        var url = "https://drive.google.com/file/d/" + data.id_pdf_cierre + "/view";
        window.open(url, "_blank") //to open new page
        console.log(data);
    }

    refreshData() {
        realTime.ref("apps/App-16008/registros").orderByValue().once("value", async snapshot => {
            const proyectos = snapshot.val();
            console.log(proyectos);
            if (proyectos) {

                const proyectosList = Object.keys(proyectos).map((key, index) => ({
                    ...proyectos[key],
                    uid: key,
                    count: (index + 1)
                }))
                var newArray = [];
                for (var i = proyectosList.length - 1; i >= 0; i--) {
                    console.log(proyectosList[i].estado === "aprobado")
                    console.log(proyectosList[i].estado);
                    if (proyectosList[i].estado === "aprobado") {
                        newArray.push(proyectosList[i]);
                    }

                }
                var finalIsnpeccion = newArray;

                this.setState({
                    proyectos: finalIsnpeccion,
                    loading: false,
                });
                console.log("finish setstate", proyectosList);
            } else {
                this.setState({ gerentes: null, loading: false });
            }
        })
    }
}

/**
 * mapDispatchToProps binding de las funciones
 */
const mapDispatchToProps = {
    showModalAction,
    showModalActionLoading,
    hideModalAction,
    hideModalActionLoading
};

function mapStateToProps(state) {
    console.log(state)
    var result = {

    }
    if (state.modalGenerico.accion === "CLOSE_RESET_FORM") {
        console.log("return modalgenerico")
        result = {
            ...result,
            openDialog: false,
            textoModalContrato: "",
            tituloModal: "",
        }

    } else if (state.modalGenerico.accion === "CLOSE") {
        console.log("return modalgenerico")
        result = {
            ...result,
            openDialog: false,
            textoModalContrato: "",
            tituloModal: "",
        }

    } else if (state.modalGenerico.accion === 'openDialog') {
        console.log("return modalgenerico")
        result = {
            ...result,
            openDialog: true,
            textoModal: state.modalGenerico.texto,
            tituloModal: state.modalGenerico.titulo,
        }

    }

    if (state.modalLoading.accion === "OPEN") {
        console.log("Cambio de openDialogLoading =>", true)
        result = {
            ...result,
            openDialogLoading: true,
        }

    } else if (state.modalLoading.accion === "CLOSE") {
        console.log("Cambio de openDialogLoading =>", false)
        result = {
            ...result,
            openDialogLoading: false,
        }

    }
    return result;
}



export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(LogEngie)
);